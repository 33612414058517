// Password strength mechanism is based on https://github.com/deanilvincent/check-password-strength?tab=readme-ov-file#readme
export type ContainType = (typeof PasswordStrengthRules)[number]["message"];

export type PasswordStrength = {
  id: number;
  length: number;
  hasLowerAndUpper: boolean;
  hasNumberAndSymbol: boolean;
  hasLength: boolean;
  isValid: boolean;
};

const PasswordStrengthIndications = [
  {
    id: 0,
    value: "Too weak",
    minDiversity: 0,
    minLength: 0
  },
  {
    id: 1,
    value: "Weak",
    minDiversity: 2,
    minLength: 6
  },
  {
    id: 2,
    value: "So-so",
    minDiversity: 4,
    minLength: 8
  },
  {
    id: 3,
    value: "Strong",
    minDiversity: 4,
    minLength: 10
  }
];

export const PasswordStrengthRules = [
  {
    regex: "[a-z]",
    message: "lowercase" as const
  },
  {
    regex: "[A-Z]",
    message: "uppercase" as const
  },
  {
    regex: "[0-9]",
    message: "number" as const
  },
  {
    regex: ".{8,}",
    message: "length" as const
  },
  {
    regex: "[\\p{L}\\p{M}]",
    message: "unicode" as const
  },
  {
    // https://owasp.org/www-community/password-special-characters
    regex: "[ !\"#$%&'()*+,-./:;<=>?@[\\\\\\]^_`{|}~]",
    message: "symbol" as const
  }
];
export function validateNewPassword(password: string): PasswordStrength {
  const passwordCopy = password || "";
  if (passwordCopy.length === 0) {
    return {
      id: 0,
      hasLength: false,
      hasLowerAndUpper: false,
      hasNumberAndSymbol: false,
      isValid: false,
      length: 0
    };
  }

  let fulfilledRules = PasswordStrengthRules.map((rule) => {
    return {
      message: rule.message,
      count: passwordCopy.match(new RegExp(rule.regex, "gu"))?.length || 0
    };
  }).filter((rule) => rule.count > 0);

  // This is kinda workaround (since I didn't manage to solve it with RegEx only)
  // We'll replace the unicode with symbol, which means that we treat unicode as symbol
  // We need to have a special treatment for Unicode letters
  // Since the RegEx we have, consider all existing letters as Unicode as well
  let unicodeCount = 0;
  const unicodeFulFilledRule = fulfilledRules.find((c) => c.message === "unicode");
  if (unicodeFulFilledRule) {
    const lowerFulfilledRule = fulfilledRules.find((c) => c.message === "lowercase");
    const upperFulfilledRule = fulfilledRules.find((c) => c.message === "uppercase");
    const symbolFulfilledRule = fulfilledRules.find((c) => c.message === "symbol");
    unicodeCount = unicodeFulFilledRule.count - (lowerFulfilledRule?.count || 0) - (upperFulfilledRule?.count || 0);

    if (unicodeCount) {
      if (symbolFulfilledRule) {
        symbolFulfilledRule.count += unicodeCount;
      } else {
        fulfilledRules.push({ message: "symbol", count: unicodeCount });
      }
    }
    fulfilledRules = fulfilledRules.filter((c) => c.message !== "unicode");
  }

  const length = passwordCopy.length;

  let fulfilledOptions = PasswordStrengthIndications.filter((option) => fulfilledRules.length >= option.minDiversity)
    .filter((option) => length >= option.minLength)
    .sort((o1, o2) => o2.id - o1.id);

  const hasLowerAndUpper =
    fulfilledRules.findIndex((c) => c.message === "lowercase") >= 0 && fulfilledRules.findIndex((c) => c.message === "uppercase") >= 0;
  const hasNumberAndSymbol =
    fulfilledRules.findIndex((c) => c.message === "number") >= 0 && fulfilledRules.findIndex((c) => c.message === "symbol") >= 0;
  const hasLength = fulfilledRules.findIndex((c) => c.message === "length") >= 0;

  return {
    id: fulfilledOptions[0].id,
    length,
    hasLowerAndUpper,
    hasNumberAndSymbol,
    hasLength,
    isValid: hasLength && hasNumberAndSymbol && hasLowerAndUpper
  } as PasswordStrength;
}
