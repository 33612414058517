<script lang="ts" context="module">
  import type { PasswordStrength } from "./passwordValidations";
</script>

<script lang="ts">
  export let passwordStrength: PasswordStrength;
</script>

<span class="font-medium">Must include at least:</span>
<ul class="ml-2 text-nxgray-600">
  <li class:text-green-700={passwordStrength.hasLength} class="flex items-center transition-colors">
    <span class="material-icons mr-1" style="font-size: 13px !important">
      {#if passwordStrength.hasLength}
        check_circle
      {:else}
        radio_button_unchecked
      {/if}
    </span>
    8 characters
  </li>
  <li class="flex items-center transition-colors" class:text-green-700={passwordStrength.hasLowerAndUpper}>
    <span class="material-icons mr-1" style="font-size: 13px !important;">
      {#if passwordStrength.hasLowerAndUpper}
        check_circle
      {:else}
        radio_button_unchecked
      {/if}
    </span>
    Upper & lower case letter
  </li>
  <li class:text-green-700={passwordStrength.hasNumberAndSymbol} class="flex items-center transition-colors">
    <span class="material-icons mr-1 transition-transform" style="font-size: 13px !important;">
      {#if passwordStrength.hasNumberAndSymbol}
        check_circle
      {:else}
        radio_button_unchecked
      {/if}
    </span>
    Number & special character (i.e $#%)
  </li>
</ul>
